import { Close } from '@aether/icons'
import { Media, Modal } from '@aether/components'
import { MediaFieldType } from '@aether/models'
import { styled, useMediaQuery } from '@aether/styles'

const TechnicalFeatures = styled('div', {
  display: 'grid',
})

const FeaturesWrapSlider = styled('div', {
  marginTop: '$12',
  display: 'flex',
  overflowX: 'scroll',
  scrollSnapType: 'x mandatory',
  scrollBehavior: 'smooth',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  width: '100%',
  '@lg': {
    overflow: 'hidden',
  },
})

const ShowTechnicalFeaturesModal = styled('div', {
  position: 'relative',
  maxWidth: '690px',
  padding: '$24 $24 $64',

  '@md': {
    minWidth: '690px',
    padding: '$48 50px $64 $48',
  },
})

const StyledCloseModal = styled('button', {
  background: 'transparent',
  position: 'absolute',
  top: '$20',
  right: '$16',
  $focusStyle: 'default',
})

const TechnicalFeaturesModalHeading = styled('ul', {
  listStyle: 'none',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  columnGap: '$12',
  padding: '0',
  marginBottom: '$12',
  width: '100%',

  '@md': {
    columnGap: '$16',
    marginBottom: '$24',
  },

  variants: {
    isMobile: {
      true: {
        display: 'none',
        '@md': {
          display: 'flex',
        },
      },
    },
  },
})

const TechnicalFeaturesModalDescription = styled('div', {
  $aetherFont: 'body02',
  textTransform: 'none',
  textAlign: 'left',
  width: '100%',
})

const TechnicalFeaturesModalFeature = styled('div', {
  width: '100%',
  $aetherFont: 'ui03',
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'left',
  '& svg': {
    display: 'block',
    margin: '0 auto',
  },
})

const TechnicalFeaturesModalTitle = styled('h1', {
  $aetherFont: 'heading05',
  $containerSpace: 'none',
  textTransform: 'none',
})

const TechnicalFeaturesFeature = styled('button', {
  $focusStyle: 'default',
  margin: 0,
  padding: 0,
  background: 'transparent',
  transition: `background ease-in 150ms`,
  borderRadius: '$r3',
  '&:hover': {
    backgroundColor: '$gray_light_third',
  },
})

const TechnicalFeaturesFeatureImage = styled('div', {
  width: 42,
  height: 42,
  margin: '0 auto',

  variants: {
    type: {
      modal: {
        width: '$72',
        height: '$72',
        padding: '$16',
        borderRadius: '$r3',
        margin: 0,
      },
    },
    selected: {
      true: {
        background: '$gray_extra_light',
      },
    },
    isMobile: {
      true: {
        '@md': {
          display: 'none',
        },
      },
    },
  },
})

const FeatureImage = styled('div', {
  width: 42,
  height: 42,
  margin: '0 auto',

  variants: {
    type: {
      modal: {
        width: '$72',
        height: '$72',
        background: '$gray_light',
        padding: '$16',
        borderRadius: '$r3',
        margin: 0,
      },
    },
    isMobile: {
      true: {
        '@md': {
          display: 'none',
        },
      },
    },
  },
})

type TechnicalFeaturesModalProps = {
  showTechnicalFeaturesModal: boolean
  handleOpenTechnicalFeaturesModal: (index: number) => void
  handleCloseTechnicalFeaturesModal: () => void
  selectedTechnicalFeature: {
    label: string
    description: string
    image: MediaFieldType
  }
  mappedFeatures: {
    label: string
    image: MediaFieldType
  }[]
}

export const TechnicalFeaturesModal = ({
  showTechnicalFeaturesModal,
  handleOpenTechnicalFeaturesModal,
  handleCloseTechnicalFeaturesModal,
  selectedTechnicalFeature,
  mappedFeatures,
}: TechnicalFeaturesModalProps) => {
  const matchesMd = useMediaQuery('md')
  return (
    <Modal
      preventScroll={true}
      isOpen={showTechnicalFeaturesModal}
      size={matchesMd ? 'intrinsic' : 'stretch-x'}
      overlayVariant={'darker'}
      onRequestClose={handleCloseTechnicalFeaturesModal}
      aria={{
        labelledby: 'technical-features-heading',
      }}
    >
      <ShowTechnicalFeaturesModal>
        <StyledCloseModal
          onClick={handleCloseTechnicalFeaturesModal}
          aria-label="Close Modal"
        >
          <Close size="medium" />
        </StyledCloseModal>
        {mappedFeatures.length > 0 && (
          <TechnicalFeatures>
            <FeaturesWrapSlider>
              <TechnicalFeaturesModalHeading isMobile>
                {mappedFeatures.map((feature, index) => (
                  <li key={feature.label}>
                    <TechnicalFeaturesFeature
                      onClick={() => handleOpenTechnicalFeaturesModal(index)}
                      aria-label={`Close modal and open ${feature.label}`}
                    >
                      <TechnicalFeaturesFeatureImage
                        type={'modal'}
                        selected={
                          feature.label === selectedTechnicalFeature.label
                        }
                      >
                        <Media
                          {...feature.image}
                          hardcropRatio="square"
                          sizes={{ default: '42px' }}
                        />
                      </TechnicalFeaturesFeatureImage>
                    </TechnicalFeaturesFeature>
                  </li>
                ))}
              </TechnicalFeaturesModalHeading>
            </FeaturesWrapSlider>
          </TechnicalFeatures>
        )}
        <TechnicalFeaturesModalFeature key={selectedTechnicalFeature.label}>
          <TechnicalFeaturesModalHeading>
            <FeatureImage type={'modal'} isMobile>
              <Media
                {...selectedTechnicalFeature.image}
                hardcropRatio="square"
                sizes={{ default: '42px' }}
              />
            </FeatureImage>
            <TechnicalFeaturesModalTitle id="technical-features-heading">
              {selectedTechnicalFeature.label}
            </TechnicalFeaturesModalTitle>
          </TechnicalFeaturesModalHeading>
          <TechnicalFeaturesModalDescription>
            {selectedTechnicalFeature.description}
          </TechnicalFeaturesModalDescription>
        </TechnicalFeaturesModalFeature>
      </ShowTechnicalFeaturesModal>
    </Modal>
  )
}
