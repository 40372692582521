import { FC, useRef, useEffect, useState, useCallback } from 'react'
import { styled, useMediaQuery } from '@aether/styles'
import { Link } from '@aether/components'
import { SidebarProps } from '@aether/models'
import { useRouter } from 'next/router'

const LinksWrap = styled('nav', {
  display: 'grid',
  gridAutoFlow: 'column',
  gridAutoColumns: 'min-content',
  gap: '$8',
  overflowX: 'scroll',
  overflowY: 'auto',
  scrollSnapType: 'x mandatory',
  scrollBehavior: 'smooth',
  scrollPaddingLeft: 'CONTAINER_SPACE_S',
  '&::-webkit-scrollbar': {
    display: 'none',
  },

  $containerSpace: 'small',
})

const StyledLink = styled(Link, {
  whiteSpace: 'nowrap',
  fontFamily: 'ui03',
})

type Props = {
  links?: SidebarProps['links']
}

export const NavigationHeader: FC<Props> = ({ links }) => {
  const isLg = useMediaQuery('lg')
  const { asPath: currentPath } = useRouter()
  const [activeSlideScrollPos, setAtiveSlideScrollPos] = useState<
    number | undefined
  >(undefined)

  const sliderRef = useRef<HTMLDivElement | null>(null)
  const sliderEl = sliderRef.current

  const activeSlideRef = useCallback<(node: HTMLElement | null) => void>(
    (node) => {
      const SLIDER_PADDING = 26

      if (node !== null) {
        const { width } = node.getBoundingClientRect()
        const { offsetLeft } = node
        const offset = offsetLeft - SLIDER_PADDING - width / 2
        setAtiveSlideScrollPos(offset)
      }
    },
    [],
  )

  // Scroll to currently active category
  useEffect(() => {
    if (activeSlideScrollPos && sliderEl) {
      sliderEl.scroll({
        top: 0,
        left: activeSlideScrollPos,
      })
    }
  }, [activeSlideScrollPos, sliderEl])

  return (
    <LinksWrap ref={sliderRef} aria-label="Sidebar navigation">
      {links?.map(({ title, path }) => {
        // TODO: fix link props
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // collection page can be also a filters page which has "/f" path followed by query
        const isActive = currentPath.includes(path)

        return (
          <StyledLink
            key={path}
            href={path}
            scroll={false}
            size="S"
            ref={isActive ? activeSlideRef : undefined}
            appearance={(() => {
              if (path === currentPath) {
                return 'badgeBlack'
              }
              if (isLg) {
                return 'badge'
              }
              return 'badgeWhite'
            })()}
          >
            {title}
          </StyledLink>
        )
      })}
    </LinksWrap>
  )
}
