import { Close, MediumCheckIcon } from '@aether/icons'
import { Modal } from '@aether/components'
import { styled, useMediaQuery } from '@aether/styles'
import { useTranslation } from 'react-i18next'
import { PerformanceFeatureBaseValue } from '../PerformanceBaseFeature/PerformanceBaseFeature'
import { PerformanceLevelItems } from '../PerformanceLevelItems/PerformanceLevelItems'

const ModalContentWrap = styled('div', {
  position: 'relative',
  padding: '$48 $24 $72',
  maxWidth: '650px',

  '@md': {
    padding: '$48 $64',
  },
})

const PerformanceLevel = styled('div', {
  marginTop: '$8',
})

const PerformanceLevelList = styled('ul', {
  marginTop: '$32',
})

const PerformanceLevelDescription = styled('p', {
  $aetherFont: 'body02',
  marginTop: '$24',
})

const PerformanceLevelListItem = styled('li', {
  $aetherFont: 'ui02',
  display: 'flex',
  alignItems: 'center',
  gap: '$8',
  marginTop: '$12',

  variants: {
    selected: {
      true: {
        fontWeight: '700',
        lineHeight: '11px',
      },
    },
  },
})

const StyledCloseModal = styled('button', {
  background: 'transparent',
  position: 'absolute',
  top: '$16',
  right: '$14',
  $focusStyle: 'default',
})

const IconPlaceholder = styled('div', {
  width: '15px',
  height: '15px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '9999px',
  background: '$black_light',

  variants: {
    isHover: {
      true: {
        transition: 'all 300ms ease',
        '&:hover': {
          cursor: 'pointer',
          background: '$black',
          '& > svg > path': {
            fill: '$white',
          },
        },
      },
    },
  },
})

const PerformanceModalLevelLabel = styled('h1', {
  $aetherFont: 'heading05',
  marginBottom: '$24',
})

const PerformanceLevelListItemTitle = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  lineHeight: '10px',
})

type PerformanceFeaturesModalProps = {
  isOpen: boolean
  handleClose: () => void
  title?: string
  description?: string
  values?: PerformanceFeatureBaseValue[]
}

export const PerformanceFeatureModal = ({
  isOpen,
  handleClose,
  title,
  description,
  values,
}: PerformanceFeaturesModalProps) => {
  const { t } = useTranslation('product')
  const matchesMd = useMediaQuery('md')
  return (
    <Modal
      preventScroll={true}
      isOpen={isOpen}
      size={matchesMd ? 'intrinsic' : 'stretch-x'}
      overlayVariant={'darker'}
      onRequestClose={handleClose}
      aria={{
        labelledby: 'performance-feature-heading',
      }}
    >
      <ModalContentWrap>
        <StyledCloseModal
          onClick={handleClose}
          aria-label={t('closeModal') ?? ''}
        >
          <Close size="medium" />
        </StyledCloseModal>
        <PerformanceLevel>
          <PerformanceModalLevelLabel id="performance-feature-heading">
            {title}
          </PerformanceModalLevelLabel>
          {values && values?.length > 0 ? (
            <PerformanceLevelItems
              title={title}
              levels={values}
              type="ProductConversionArea"
            />
          ) : null}
          <PerformanceLevelList>
            {values?.map((value) => (
              <PerformanceLevelListItem
                key={value.title}
                selected={value.selected}
              >
                {value.selected ? <MediumCheckIcon /> : <IconPlaceholder />}
                <PerformanceLevelListItemTitle>
                  {value.title} - {value.subtitle}
                </PerformanceLevelListItemTitle>
              </PerformanceLevelListItem>
            ))}
          </PerformanceLevelList>
          <PerformanceLevelDescription>
            {description}
          </PerformanceLevelDescription>
        </PerformanceLevel>
      </ModalContentWrap>
    </Modal>
  )
}
