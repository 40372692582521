import {
  Badge,
  Link,
  Modal,
  ProductAccommodationPicker,
  ProductColorPicker,
  ShopifyDescriptionHTMLWrap,
  SizeGuide,
} from '@aether/components'
import { ROUTE_BASE_COLLECTIONS } from '@aether/configs'
import { ProductOptionGroup } from './ProductOption/ProductOptionGroup'
import { AddToCartButton } from './AddToCartButton/AddToCartButton'
import { StickyAddToCartButton } from './AddToCartButton/StickyAddToCartButton'
import { FC, useEffect, useState } from 'react'
import { styled, useMediaQuery } from '@aether/styles'
import {
  ShopifyProduct,
  ShopifyProductsData,
  SiteConfiguration,
} from '@aether/models'
import { useTranslation } from 'react-i18next'
import { useComputedProductData } from './useComputedProductData'
import { GTMOpenSizeGuide } from '@aether/services/gtm-service'
import { useRouter } from 'next/router'
import { useDiscounts } from '@aether/discounts/utils-context'
import { useCustomerContext } from '@aether/account/utils-customer-context'
import { ProductBreadcrumbs } from './ProductBreadcrumbs'
import { formatProductName } from '@aether/utils'
import { ReviewsModal, StarsRating } from '@aether/review'
import { useCartContext } from '@aether/cart'
import { CartUpsellModal } from './CartUpsellModal/CartUpsellModal'
import { JoinWaitlistForm } from './JoinWaitlistForm'

const Root = styled('div', {
  paddingBottom: '$24',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '$24',
  $containerSpace: 'small',
  '@lg': {
    paddingTop: '$16',
    $containerSpace: 'none',
    borderTop: '1px solid $black',
  },
})

const ProductMainInfo = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$16',
})

const ProductAccommodationWrap = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$8',
})

const ProductTitle = styled('h1', {
  $aetherFont: 'heading04',
  '@lg': {
    $aetherFont: 'heading03',
  },
})

const MainCategory = styled('span', {
  $aetherFont: 'ui02',
})

const ProductSecondaryDescription = styled('p', {
  $aetherFont: 'body02',
  whiteSpace: 'pre-wrap',
})

const ModelDescription = styled('p', {
  $aetherFont: 'body04',
  whiteSpace: 'pre-wrap',
})

const StyledVariants = styled('div', {
  width: '100%',
  display: 'grid',
  gridGap: '$24',
})
const BadgesWrapper = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '$12',
  '&:empty': {
    display: 'none',
  },
})

type Props = {
  siteConfiguration?: SiteConfiguration
  productData?: ShopifyProduct
  shopifyData?: ShopifyProductsData
  productId: string
}

export const ProductDetails: FC<Props> = ({
  siteConfiguration,
  productData,
  shopifyData,
  productId,
}) => {
  const collections = shopifyData?.collections || []
  const colorsMap = siteConfiguration?.colorsMap || []
  const sizeGuide = siteConfiguration?.sizeGuides.find(
    (sizeGuide) => sizeGuide?.shopifyId === productData?.sizeGuide?.value,
  )
  const [isSizeGuideOpen, setSizeGuideOpen] = useState(false)
  const [showOptionsErrors, setShowOptionsErrors] = useState(false)

  const currentProductColor = colorsMap?.find(
    (color) => color.shopifyId === productData?.colors?.value,
  )

  const products = shopifyData?.products || {}
  const router = useRouter()
  const { t } = useTranslation('product')
  const isMd = useMediaQuery('md')
  const { userRole } = useCustomerContext()
  const { matchedDiscount } = useDiscounts({ collections, userRole, productId })
  const isVip = userRole === 'vip'

  const [currentVariant, optionsToSelect, onOptionChange] =
    useComputedProductData(productData, shopifyData)

  const vipProduct = productData?.vipProduct
  const preOrderIndicator = productData?.preOrderIndicator?.value === 'true'
  const preOrderMessageValue = productData?.preOrderMessage?.value
  const greyBadgeValue = productData?.greyBadge?.value
  const availableForSale = productData?.availableForSale
  const secondaryDescription = productData?.secondaryDescription?.value
  const modelDescription = productData?.modelDescription?.value
  const isEvent = productData?.pdpType?.value?.toLowerCase() === 'event'

  const availableAmount = currentVariant?.quantityAvailable
  const LOW_STOCK_THRESHOLD = 10

  const isLowStock = availableAmount
    ? availableAmount <= LOW_STOCK_THRESHOLD
    : false

  const badge = productData?.badge?.value
  const closeSizeGuide = () => {
    setSizeGuideOpen(false)
  }

  const openSizeGuide = () => {
    setSizeGuideOpen(true)
    GTMOpenSizeGuide('SectionProductConversionArea')
  }

  const scrollBackToTop = () => {
    setShowOptionsErrors(true)
    const scrollElement =
      document.getElementById('joinWaitlistButton') ||
      document.getElementById('addToCartButton')
    if (scrollElement) {
      scrollElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }

  const mainCollection = productData?.collections?.edges.find(
    (edge) => edge.node.handle === productData?.mainCollectionHandle?.value,
  )?.node

  const isFinalSale =
    productData?.finalSale &&
    productData?.finalSale.value &&
    JSON.parse(productData.finalSale.value)
  const { addCartLines, setCartUpsellModalOpen } = useCartContext()
  const handleAddToCartClick = async () => {
    if (!currentVariant) return
    if (!currentVariant.availableForSale) {
      scrollBackToTop()
      return
    }
    addCartLines(
      preOrderMessageValue
        ? [
            {
              merchandiseId: currentVariant.id,
              quantity: 1,
              attributes: [
                {
                  key: 'Pre-order',
                  value: preOrderMessageValue,
                },
              ],
            },
          ]
        : [
            {
              merchandiseId: currentVariant.id,
              quantity: 1,
            },
          ],
    )

    return setCartUpsellModalOpen(true)
  }
  useEffect(() => {
    const handleRouteChange = () => {
      setShowOptionsErrors(false)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <Root>
      <ProductMainInfo>
        <ProductBreadcrumbs breadcrumbs={shopifyData?.breadcrumbs} />
        <ProductTitle id="main-product-title">
          {formatProductName(productData?.title || '')}
        </ProductTitle>
        {productData?.descriptionHtml && isEvent && (
          <ShopifyDescriptionHTMLWrap>
            <div
              dangerouslySetInnerHTML={{
                __html: productData.descriptionHtml,
              }}
            />
          </ShopifyDescriptionHTMLWrap>
        )}
        {productData !== undefined && !isEvent && (
          <ProductRating
            id={productData.id}
            title={productData.title}
            key={productData.id}
          />
        )}
        <BadgesWrapper>
          {badge && <Badge text={badge} appearance="action" />}
          {isFinalSale && (
            <Badge text={t('finalSale')} appearance="actionRed" />
          )}
          {vipProduct && isVip && (
            <Badge text={t('vipBadge')} appearance="vip" />
          )}
          {greyBadgeValue && (
            <Badge text={greyBadgeValue} appearance="default" />
          )}
        </BadgesWrapper>
        {mainCollection && (
          <Link
            href={`${ROUTE_BASE_COLLECTIONS}/${mainCollection.handle}`}
            appearance="block"
          >
            <MainCategory>{mainCollection.title}</MainCategory>
          </Link>
        )}
      </ProductMainInfo>
      {isEvent ? (
        <ProductAccommodationWrap>
          <ProductAccommodationPicker
            productId={productId}
            products={products}
          />

          <StyledVariants>
            {optionsToSelect?.length > 0 &&
              optionsToSelect?.map((optionGroup) => (
                <ProductOptionGroup
                  {...optionGroup}
                  sizeGuide={sizeGuide}
                  openSizeGuide={openSizeGuide}
                  onChange={onOptionChange}
                  key={optionGroup.id}
                  showErrors={showOptionsErrors}
                  disableLegend={true}
                  isLowStock={isLowStock}
                  isEvent={isEvent}
                  availableAmount={availableAmount}
                />
              ))}
          </StyledVariants>
        </ProductAccommodationWrap>
      ) : (
        <>
          <ProductColorPicker
            colorsMap={colorsMap}
            productId={productId}
            products={products}
            productTitle={formatProductName(productData?.title || '')}
            size="L"
          />

          <StyledVariants>
            {optionsToSelect?.length > 0 &&
              optionsToSelect?.map((optionGroup) => (
                <ProductOptionGroup
                  {...optionGroup}
                  sizeGuide={sizeGuide}
                  openSizeGuide={openSizeGuide}
                  onChange={onOptionChange}
                  key={optionGroup.id}
                  showErrors={showOptionsErrors}
                  isLowStock={isLowStock}
                  productTitle={productData?.title}
                  availableAmount={availableAmount}
                />
              ))}
          </StyledVariants>
        </>
      )}
      {secondaryDescription && (
        <ProductSecondaryDescription>
          {secondaryDescription}
        </ProductSecondaryDescription>
      )}
      {modelDescription && (
        <ModelDescription>{modelDescription}</ModelDescription>
      )}
      <Modal
        preventScroll={true}
        isOpen={isSizeGuideOpen}
        size={isMd ? 'intrinsic' : 'stretch'}
        position={isMd ? 'top100' : 'center'}
        onRequestClose={() => setSizeGuideOpen(false)}
      >
        <SizeGuide closeSizeGuide={closeSizeGuide} sizeGuide={sizeGuide} />
      </Modal>
      {(!currentVariant && availableForSale) ||
      currentVariant?.availableForSale ? (
        <AddToCartButton
          variant={currentVariant}
          productData={productData}
          color={currentProductColor}
          onNoVariantSelected={() => setShowOptionsErrors(true)}
          onAddToCart={handleAddToCartClick}
          preOrderIndicator={preOrderIndicator}
          availableForSale={availableForSale}
          discount={matchedDiscount}
        />
      ) : (
        <JoinWaitlistForm
          currentVariant={currentVariant}
          setShowOptionsErrors={setShowOptionsErrors}
        />
      )}

      <StickyAddToCartButton
        variant={currentVariant}
        productData={productData}
        color={currentProductColor}
        onNoVariantSelected={scrollBackToTop}
        onAddToCart={handleAddToCartClick}
        preOrderIndicator={preOrderIndicator}
        availableForSale={availableForSale}
        discount={matchedDiscount}
      />
      <CartUpsellModal
        color={currentProductColor}
        productData={productData}
        variant={currentVariant}
        shopifyData={shopifyData}
        discount={matchedDiscount}
      />
      {productData?.descriptionHtml && !isEvent && (
        <ShopifyDescriptionHTMLWrap>
          <div
            dangerouslySetInnerHTML={{
              __html: productData.descriptionHtml,
            }}
          />
        </ShopifyDescriptionHTMLWrap>
      )}
    </Root>
  )
}

const ProductRatingWrapper = styled('div', {
  display: 'flex',
  gap: 16,
  alignItems: 'center',

  '&:has(:is(.kl_reviews__empty_star:first-child, .kl_reviews__full_star:first-child, .kl_reviews__partial_star:first-child)) #aether-open-reviews-modal-button':
    {
      display: 'flex',
    },

  '&:has(:is(.kl_reviews__full_star:first-child, .kl_reviews__partial_star:first-child)) #aether-open-reviews-modal-button':
    {
      ':first-child': {
        display: 'none',
      },
      ':last-child': {
        display: 'flex',
      },
    },

  '&:has(.kl_reviews__empty_star:first-child) #aether-open-reviews-modal-button':
    {
      ':first-child': {
        display: 'flex',
      },
      ':last-child': {
        display: 'none',
      },
    },
})

function ProductRating({ id, title }: { id: string; title: string }) {
  return (
    <ProductRatingWrapper>
      <StarsRating productId={id} productTitle={title} />
      <ReviewsModal productId={id} />
    </ProductRatingWrapper>
  )
}
