import { FC, Fragment } from 'react'
import { styled } from '@aether/styles'
import {
  FeaturesAndFitTechSectionType,
  ProductTemperatureType,
} from '@aether/models'
import { Media } from '@aether/components'
import { useTranslation } from 'react-i18next'

const Root = styled('div', {
  paddingTop: '$20',
  paddingBottom: '$40',
  width: '100%',
  borderTop: '1px solid $black',

  '@md': {
    paddingBottom: '$48',
  },
})

type Props = FeaturesAndFitTechSectionType & {
  className?: string
  technicalFeaturesMap: ProductTemperatureType[] | undefined
  techFeatures?: string[]
  isModal: boolean
}

const FeatureHeader = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  columnGap: '$12',
  padding: '0',
  width: '100%',

  variants: {
    isModal: {
      true: {
        marginBottom: '$4',
      },
      false: {
        marginBottom: '$12',
        '@md': {
          columnGap: '$16',
          marginBottom: '$24',
        },
      },
    },
  },
})

const FeatureImage = styled('div', {
  borderRadius: '$r3',
  margin: 0,
  background: '$black_extra_light',

  variants: {
    isModal: {
      true: {
        padding: '$12',
        width: '$64',
        height: '$64',
      },
      false: {
        padding: '$12',
        width: '$64',
        height: '$64',
      },
    },
  },
})

const FeatureTitle = styled('p', {
  $aetherFont: 'heading05',
  $containerSpace: 'none',
  textAlign: 'left',
  textTransform: 'none',
})

const FeatureDescription = styled('p', {
  $aetherFont: 'body02',
  textTransform: 'none',
  textAlign: 'left',
})

const FeaturesContent = styled('ul', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  listStyle: 'none',

  variants: {
    isModal: {
      true: {
        gap: '$40',
        margin: '$32 0 0',
        '@md': {
          margin: '$40 0 0',
          gridTemplateColumns: '1fr',
          gap: '$64',
        },
        '@xl': {
          gridTemplateColumns: '1fr 1fr',
        },
      },
      false: {
        margin: '$40 0',
        gap: '$40',
        '@md': {
          gridTemplateColumns: '1fr 1fr',
          gap: '$40',
        },

        '@xl': {
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: '$32',
        },
      },
    },
  },
})

const Feature = styled('li', {
  width: '100%',
  $aetherFont: 'ui03',
  display: 'flex',
  gap: '$12',
  flexDirection: 'column',
  justifyContent: 'left',
  textAlign: 'center',
  '& svg': {
    display: 'block',
    margin: '0 auto',
  },
})

const FeaturesTitle = styled('h3', {
  $aetherFont: 'heading06Caps',
  letterSpacing: '1.3px',
})

export const FeaturesAndFitTechSection: FC<Props> = ({
  className,
  technicalFeaturesMap,
  techFeatures,
  isModal,
}) => {
  const { t } = useTranslation('product')
  return (
    <Root className={className}>
      {techFeatures && technicalFeaturesMap && (
        <>
          <FeaturesTitle>{t('technicalFeatures')}</FeaturesTitle>
          <FeaturesContent isModal={isModal}>
            {techFeatures.map((feature, index) => (
              <Fragment key={`${feature}-${index}`}>
                {technicalFeaturesMap.map((feat) => {
                  if (feat.shopifyId !== feature) return null

                  return (
                    <Feature key={feat.label}>
                      <FeatureHeader>
                        <FeatureImage isModal={isModal}>
                          <Media
                            {...feat.image}
                            hardcropRatio="square"
                            sizes={{ default: '42px', md: '50px' }}
                          />
                        </FeatureImage>
                        <FeatureTitle>{feat.label}</FeatureTitle>
                      </FeatureHeader>
                      <FeatureDescription>
                        {feat.description}
                      </FeatureDescription>
                    </Feature>
                  )
                })}
              </Fragment>
            ))}
          </FeaturesContent>
        </>
      )}
    </Root>
  )
}
