import { styled } from '@aether/styles'
import { useState } from 'react'
import { TemperatureRangeTechSection } from '../../SectionTechnicalSpecifications/TemperatureRangeTechSection/TemperatureRangeTechSection'
import { PerformanceTemperatureModal } from './PerformanceTemperatureModal'

const PerformanceLevel = styled('div', {
  marginTop: '$28',
  cursor: 'pointer',
})

const PerformanceLevelTemp = styled('div', {})

export type PerformanceTemperatureValue = {
  title?: string
  subtitle?: string
  selected: boolean
  minTempValue: number
  maxTempValue: number
  hiddenDescriptionF?: string
  hiddenDescriptionC?: string
}
type PerformanceTemperatureFeatureProps = {
  title?: string
  description?: string
  values: PerformanceTemperatureValue[]
}
export const PerformanceTemperatureFeature = ({
  values,
  title,
  description,
}: PerformanceTemperatureFeatureProps) => {
  const [performanceTemperatureModalOpen, setPerformanceTemperatureModalOpen] =
    useState(false)

  return values && values.length !== 0 ? (
    <>
      <PerformanceLevel
        onClick={() => setPerformanceTemperatureModalOpen(true)}
      >
        <PerformanceLevelTemp>
          {(() => {
            const selectedValue = values?.find((value) => value.selected)
            return (
              <TemperatureRangeTechSection
                title={title}
                hiddenDescriptionF={selectedValue?.hiddenDescriptionF}
                hiddenDescriptionC={selectedValue?.hiddenDescriptionC}
                selectedMinTemp={selectedValue?.minTempValue ?? 0}
                selectedMaxTemp={selectedValue?.maxTempValue ?? 0}
                isProductConversionArea={true}
              />
            )
          })()}
        </PerformanceLevelTemp>
      </PerformanceLevel>
      <PerformanceTemperatureModal
        title={title}
        description={description}
        isOpen={performanceTemperatureModalOpen}
        handleClose={() => setPerformanceTemperatureModalOpen(false)}
        values={values}
      />
    </>
  ) : null
}
