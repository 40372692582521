import { styled } from '@aether/styles'
import { MediumCheckIcon } from '@aether/icons'
import { TemperatureRangeTechSection } from '../TemperatureRangeTechSection/TemperatureRangeTechSection'
import { PerformanceTemperatureValue } from '../../SectionProductConversionArea/PerfomanceTemperatureFeature/PerformanceTemperatureFeature'

const PerformanceLevel = styled('div', {})

const PerformanceLevelTemp = styled('div', {})

const PerformanceLevelList = styled('ul', {
  marginTop: '$32',
})
const PerformanceLevelListItem = styled('li', {
  $aetherFont: 'ui02',
  display: 'flex',
  alignItems: 'center',
  gap: '$8',
  marginTop: '$12',
  textTransform: 'uppercase',

  variants: {
    selected: {
      true: {
        fontWeight: '700',
      },
    },
  },
})

const IconPlaceholder = styled('div', {
  width: '15px',
  height: '15px',
  display: 'block',
  borderRadius: '9999px',
  background: '$black_light',
})
const PerformanceLevelDescription = styled('p', {
  $aetherFont: 'body02',
  marginTop: '$24',
})

type PerformanceTemperatureFeatureProps = {
  title?: string
  description?: string
  values: PerformanceTemperatureValue[]
}
export const PerformanceTemperatureFeature = ({
  title,
  description,
  values,
}: PerformanceTemperatureFeatureProps) => {
  return values && values.length !== 0 ? (
    <PerformanceLevel>
      <PerformanceLevelTemp>
        {(() => {
          const selectedValue = values.find((item) => item.selected)
          return (
            <TemperatureRangeTechSection
              title={title}
              hiddenDescriptionF={selectedValue?.hiddenDescriptionF}
              hiddenDescriptionC={selectedValue?.hiddenDescriptionC}
              selectedMinTemp={selectedValue?.minTempValue ?? 0}
              selectedMaxTemp={selectedValue?.maxTempValue ?? 0}
            />
          )
        })()}
      </PerformanceLevelTemp>

      <PerformanceLevelList>
        {values.map((item) => (
          <PerformanceLevelListItem key={item.title} selected={item.selected}>
            {item.selected ? <MediumCheckIcon /> : <IconPlaceholder />}
            {item.title} - {item.subtitle}
          </PerformanceLevelListItem>
        ))}
      </PerformanceLevelList>
      <PerformanceLevelDescription>{description}</PerformanceLevelDescription>
    </PerformanceLevel>
  ) : null
}
