import { FC, useState } from 'react'
import { SectionTechnicalSpecificationsProps } from '@aether/models'
import { styled, useMediaQuery } from '@aether/styles'
import {
  Container,
  SectionWrapper,
  Modal,
  SizeGuide,
  ShopifyDescriptionHTMLWrap,
} from '@aether/components'
import { AccordionTechSection } from './AccordionTechSection'
import { FeaturesAndFitTechSection } from './FeaturesAndFitTechSection'
import { useTranslation } from 'react-i18next'
import { formatProductName, getProductMetafieldValues } from '@aether/utils'
import { Close } from '@aether/icons'
import { PerformanceFeaturesTechSection } from './PerformanceFeaturesTechSection'

const Root = styled('div', {
  padding: '$32 0 1px 0',
  '@md': {
    padding: '$72 0 1px 0',
  },
  variants: {
    isRenderedInModal: {
      true: {
        minWidth: '60vw',
        '@md': {
          padding: '$48 0 1px 0',
        },
      },
      false: {},
    },
  },
})

const StickyTitleContainer = styled('div', {
  variants: {
    isModal: {
      true: {
        position: 'sticky',
        paddingTop: '$16',
        zIndex: '$content',
        top: '0',
        '@md': {
          position: 'relative',
        },
      },
    },
  },
})

const TitleContainer = styled('div', {
  paddingTop: '$16',
  paddingBottom: '$36',
  borderTop: '1px solid',
  display: 'grid',
  gap: '$16',
  gridAutoFlow: 'row',
  justifyItems: 'start',
  gridTemplateColumns: 'auto 1fr min-content',
  '@lg': {
    gridAutoFlow: 'column',
    gridTemplateColumns: 'auto 1fr min-content',
    alignItems: 'center',
    paddingTop: '$24',
  },
})

const Title = styled('h2', {
  $aetherFont: 'heading02',
  '@lg': {
    $aetherFont: 'heading03',
  },
})

const StyledCloseModal = styled('button', {
  gridColumn: '3/4',
  background: 'transparent',
  $focusStyle: 'default',
  padding: '$4',
  display: 'flex',
  alignItems: 'start',
})

const ProductDescriptionContainer = styled('div', {
  '@lg': {
    maxWidth: '85%',
  },
  variants: {
    isModal: {
      true: {
        paddingBottom: '$48',

        '@md': {
          paddingBottom: '$40',
        },
      },
      false: {
        paddingBottom: '$56',
      },
    },
  },
})
const ProductName = styled('h3', {
  $aetherFont: 'heading05',
  marginBottom: '$16',
})

export const SectionTechnicalSpecifications: FC<
  SectionTechnicalSpecificationsProps & {
    isRenderedInModal?: boolean
    handleModalClose?: () => void
    isModal?: boolean
  }
> = ({
  backgroundColor,
  sections,
  productId,
  shopifyData,
  siteConfiguration,
  // This Section can be used both in PageBuilder & as Modal under the SectionProductConversionArea
  isRenderedInModal = false,
  handleModalClose,
  isModal = false,
}) => {
  const [isSizeGuideOpen, setSizeGuideOpen] = useState(false)
  const isMd = useMediaQuery('md')

  const { t } = useTranslation('sectionTechnicalSpecifications')

  const productData =
    productId && shopifyData ? shopifyData.products[productId] : null

  const isEvent = productData?.pdpType?.value?.toLowerCase() === 'event'

  const techFeatures = getProductMetafieldValues('techFeatures', productData)

  const technicalFeaturesMap = siteConfiguration?.technicalFeaturesMap

  const sizeGuide = siteConfiguration?.sizeGuides.find(
    (sizeGuide) => sizeGuide.shopifyId === productData?.sizeGuide?.value,
  )

  const closeSizeGuide = () => {
    setSizeGuideOpen(false)
  }
  return (
    <SectionWrapper withBackground={Boolean(backgroundColor)}>
      <Root
        css={{
          backgroundColor: backgroundColor?.rgba || 'unset',
          minHeight: isRenderedInModal ? '100vh' : 'auto',
        }}
        id="sectionTechnicalSpecifications"
        isRenderedInModal={isRenderedInModal}
      >
        <Container>
          <StickyTitleContainer
            css={{
              backgroundColor: backgroundColor?.rgba || 'unset',
            }}
            isModal={isModal}
          >
            <TitleContainer>
              <Title>{t('title')}</Title>
              {isRenderedInModal && handleModalClose && (
                <StyledCloseModal
                  onClick={handleModalClose}
                  aria-label={t('closeModal') ?? ''}
                >
                  <Close size="medium" />
                </StyledCloseModal>
              )}
            </TitleContainer>
          </StickyTitleContainer>
          <ProductDescriptionContainer isModal={isModal}>
            <ProductName>
              {formatProductName(productData?.title || '')}
            </ProductName>
            {productData?.descriptionHtml && !isEvent && (
              <ShopifyDescriptionHTMLWrap>
                <div
                  dangerouslySetInnerHTML={{
                    __html: productData.descriptionHtml,
                  }}
                />
              </ShopifyDescriptionHTMLWrap>
            )}
          </ProductDescriptionContainer>
          {sections?.map((section) => {
            switch (section?.type) {
              case 'accordionTechSection':
                return <AccordionTechSection {...section} key={section?.id} />
              //we reuse old temperatureRangeTechSection for the new one to omit data migration on that
              case 'temperatureRangeTechSection':
                return (
                  <PerformanceFeaturesTechSection
                    key={section?.id}
                    isModal={isModal}
                    productData={productData}
                    performanceFeaturesMap={
                      siteConfiguration?.performanceFeaturesMap
                    }
                  />
                )
              case 'featuresAndFitTechSection':
                return (
                  <FeaturesAndFitTechSection
                    {...section}
                    key={section?.id}
                    isModal={isModal}
                    technicalFeaturesMap={technicalFeaturesMap}
                    techFeatures={techFeatures}
                  />
                )
              default:
                return null
            }
          })}
        </Container>
      </Root>

      <Modal
        preventScroll={true}
        isOpen={isSizeGuideOpen}
        size={isMd ? 'intrinsic' : 'stretch'}
        position={isMd ? 'top100' : 'center'}
        onRequestClose={closeSizeGuide}
      >
        <SizeGuide closeSizeGuide={closeSizeGuide} sizeGuide={sizeGuide} />
      </Modal>
    </SectionWrapper>
  )
}
