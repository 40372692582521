import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { SectionJournalHeaderProps } from '@aether/models'
import { JournalHeadingIcon } from './JournalHeadingIcon'
import { styled, useMediaQuery } from '@aether/styles'
import { Container, Link, Pictogram } from '@aether/components'
import { ROUTE_BASE_JOURNAL } from '@aether/configs'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'

const HeadingWrap = styled('div', {
  display: 'grid',
  gridTemplateAreas: `"headingIcon"
                      "pictogram"
                      "quote"`,
  gridGap: '$20',
  '@lg': {
    gridTemplateAreas: '"quote headingIcon pictogram"',
    gridTemplateColumns: 'minmax(180px, 13%) 1fr minmax(180px, 13%)',
    $lin: {
      gridGap: ['$0', '$96'],
    },
  },
})

const HeadingLink = styled(Link, {
  gridArea: 'headingIcon',
})

const Quote = styled('span', {
  gridArea: 'quote',
  $aetherFont: 'ui06',
  textAlign: 'center',
  alignSelf: 'center',
  justifySelf: 'center',
  maxWidth: '250px',
})

const CategoriesSlider = styled('nav', {
  display: 'grid',
  gridAutoFlow: 'column',
  overflowX: 'scroll',
  overflowY: 'auto',
  scrollSnapType: 'x mandatory',
  scrollBehavior: 'smooth',
  $lin: {
    scrollPaddingLeft: 'CONTAINER_SPACE_S',
    paddingLeft: 'CONTAINER_SPACE_S',
    paddingRight: 'CONTAINER_SPACE_S',
    gridGap: ['$12', '$56'],
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

const StyledLink = styled(Link, {
  whiteSpace: 'nowrap',
})

const CarouselWrap = styled('div', {
  position: 'sticky',
  top: 0,
  zIndex: '$journalHeader',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '$beige',
  padding: '$12 0',
})

const PostsCount = styled('div', {
  paddingTop: '$12',
  $aetherFont: 'ui02',
  display: 'grid',
  gridTemplateColumns: 'auto min-content auto',
  gridGap: '$12',
  whiteSpace: 'nowrap',
  '&:before': {
    content: '',
    width: '100%',
    borderBottom: '1px solid black',
    height: 0,
    alignSelf: 'center',
  },
  '&:after': {
    content: '',
    width: '100%',
    borderBottom: '1px solid black',
    height: 0,
    alignSelf: 'center',
  },
  '@lg': {
    paddingTop: '$20',
  },
})

const StyledHeadingContainer = styled(Container, {
  backgroundColor: '$beige',
  paddingTop: '$20',
  paddingBottom: '$12',
  '@lg': {
    paddingBottom: '$36',
  },
})

const StyledPostCountContainer = styled(Container, {
  backgroundColor: '$beige',
  paddingBottom: '$20',
})

export const SectionJournalHeader: FC<SectionJournalHeaderProps> = ({
  categories,
  pictogram,
  postsCount,
  caption,
}) => {
  const { t } = useTranslation('journal')
  const { asPath: currentPath } = useRouter()
  const isLg = useMediaQuery('lg')
  const [activeSlideScrollPos, setAtiveSlideScrollPos] = useState<
    number | undefined
  >(undefined)

  const sliderRef = useRef<HTMLElement | null>(null)
  const sliderEl = sliderRef.current

  const activeSlideRef = useCallback<(node: HTMLElement | null) => void>(
    (node) => {
      if (node !== null) {
        const { width } = node.getBoundingClientRect()
        const { offsetLeft } = node
        setAtiveSlideScrollPos(offsetLeft - width)
      }
    },
    [],
  )

  // Scroll to currently active category
  useEffect(() => {
    if (activeSlideScrollPos && sliderEl) {
      sliderEl.scroll({
        top: 0,
        left: activeSlideScrollPos,
      })
    }
  }, [activeSlideScrollPos, sliderEl])

  // TODO: replace default image with sanity custom
  return (
    <>
      <StyledHeadingContainer>
        <HeadingWrap>
          <Quote>{caption}</Quote>
          <HeadingLink
            href={ROUTE_BASE_JOURNAL}
            appearance="block"
            ariaLabel="Journal Heading"
          >
            <JournalHeadingIcon />
          </HeadingLink>
          <Pictogram pictogram={pictogram} />
        </HeadingWrap>
      </StyledHeadingContainer>
      <CarouselWrap>
        <CategoriesSlider
          ref={sliderRef}
          aria-label={`${caption} - categories`}
        >
          {categories?.map(({ title, path }, index) => {
            const isActive = path === currentPath

            return (
              <StyledLink
                href={path}
                key={index}
                scroll={false}
                appearance={(() => {
                  if (isActive) {
                    return 'badgeBlack'
                  }
                  if (isLg) {
                    return 'badge'
                  }
                  return 'badgeWhite'
                })()}
                size="S"
                ref={isActive ? activeSlideRef : undefined}
              >
                {title}
              </StyledLink>
            )
          })}
        </CategoriesSlider>
      </CarouselWrap>
      <StyledPostCountContainer>
        {typeof postsCount !== 'undefined' ? (
          <PostsCount>
            <span>{t('postsCount', { value: postsCount })}</span>
          </PostsCount>
        ) : null}
      </StyledPostCountContainer>
    </>
  )
}
